import React, { useEffect, useState } from "react";
import { JOIN_MEDIPAC_API } from "../../../../Utilities/APIs";

import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewWhyJoinMedipac({ targetID }) {
  const [joinMedipac, setJoinMedipacInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(JOIN_MEDIPAC_API + targetID);
      setJoinMedipacInfo(data);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {joinMedipac && (
        <>
          <h1>Name</h1>
          <p>{joinMedipac.name}</p>

          <h1>Designation</h1>
          <p>{joinMedipac.description}</p>
          <h1> Image</h1>
          <Image imgLink={joinMedipac.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewWhyJoinMedipac;
