import axios from "axios";
function useAxiosAuthInstance() {
  console.log(
    "process.env.REACT_APP_BACKEND_URL",
    process.env.REACT_APP_BACKEND_URL
  );
  const axiosAuthInstance = axios.create({
    // baseURL: process.env.REACT_APP_BACKEND_URL + 'api/public/employeeAuth/',
    baseURL: process.env.REACT_APP_BACKEND_URL + "api/",
  });

  return axiosAuthInstance;
}

export default useAxiosAuthInstance;
