import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiImageLine } from "react-icons/ri";
import { SiAboutdotme } from "react-icons/si";
import { RiTeamFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import { FaBlog } from "react-icons/fa";
function AboutScreen() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"About-Us"} navCardLink={"/aboutUs"}>
        <SiAboutdotme />
      </NavCard>
      <NavCard cardName={"Manager"} navCardLink={"/manager"}>
        <MdManageAccounts />
      </NavCard>
      <NavCard cardName={"Our-Team"} navCardLink={"/ourTeam"}>
        <RiTeamFill />
      </NavCard>
      <NavCard cardName={"Awards"} navCardLink={"/awards"}>
        <FaAward />
      </NavCard>
      <NavCard cardName={"Testimonial"} navCardLink={"/testimonial"}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={"News"} navCardLink={"/news"}>
        <RiMessage2Fill />
      </NavCard>
      <NavCard cardName={"Blogs"} navCardLink={"/blogs"}>
        <FaBlog />
      </NavCard>
    </NavCardList>
  );
}

export default AboutScreen;
