// import { backendUrl, spacesBucketUrl } from "../Constants";

// export const BASE_URL = backendUrl;
// export const IMAGE_URL = spacesBucketUrl;
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const IMAGE_URL = process.env.REACT_APP_SPACES_URL;
export const API_URL = BASE_URL + 'api/';

export const EMPLOYEES_API = 'employees/';
export const DESIGNATIONS_API = 'designations/';
export const EMPLOYEE_DESIGNATIONS_API = 'employeeDesignations/';
export const CUSTOMERS_API = 'customers/';

export const APP_SETTINGS_API = 'appSettings/';
export const CONTACTS_API = 'contacts/';

export const PRODUCTS_API = 'products/';
export const CATEGORYS_API = 'categories/';
export const PRODUCTS_ASSET_API = 'productAssets/';

export const ABOUT_US_API = 'aboutUs/';
export const MANAGER_API = 'manager/';
export const OUr_TEAM_API = 'ourTeam/';
export const Award_API = 'awards/';
export const TESTIMONIAL_API = 'testimonial/';

export const JOIN_MEDIPAC_API = 'whyJoinMedipac/';
export const CAREER_OPENING_API = 'careerOpenings/';
export const CAREER_APPLY_API = 'carrerApply/';
export const NEWS_API = 'news/';
export const NewsImage_API = 'news_image/';
export const BLOGS_API = 'blogs/';
export const BLOG_IMAGES_API = 'blogImage/';
export const Supplier_API = 'supplier/';
export const SolutionPage_API = 'solutionPage/';
export const CaseStudies_API = 'caseStudies/';
export const CaseStudy_IMAGES_API = 'caseStudyImages/';
export const Gallery_API = 'gallery/info/';
export const GalleryItem_API = 'gallery/galleryItem/';
export const Gallery_Single_Item_API = 'gallery/gallerySinglItem/';

export const OUR_SOLUTION_API = 'ourSolutions/';
export const BANNER_API = 'homeBanners/';
export const Newsletter_API = 'newsletter/';
export const SocialLinks_API = 'social_links/';
