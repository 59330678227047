import YouTube from "react-youtube";
import "./Video.css";
function Video({ src }) {
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  return <YouTube videoId={src} opts={opts} />;
}

export default Video;
