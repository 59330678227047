import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import { CAREER_APPLY_API } from '../../../../../Utilities/APIs';
import ViewApplicantDetails from './ViewApplicantDetails';

function CarrerApply() {
  const [careerOpenings, setCareerOpenings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const axiosInstance = useAxiosInstance();
  const params = useParams();

  useEffect(() => {
    async function fetchAndSetProducts() {
      try {
        const { data } = await axiosInstance.get(
          `${CAREER_APPLY_API}/${params.carreerId}`,
        );
        setCareerOpenings(data);
      } catch (error) {
        console.error('Error fetching career openings:', error);
      }
    }
    fetchAndSetProducts();
  }, [axiosInstance, params.carreerId]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <h1 className='heading'>{`Total Applicants (${careerOpenings.length})`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Name' />
            <CRUDth th='Email' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {careerOpenings.map((careerOpening) => (
              <CRUDTableRow key={careerOpening._id}>
                <ShortTextCell
                  text={`${careerOpening.firstName} ${careerOpening.lastName}`}
                />
                <ShortTextCell text={careerOpening.email} />
                <td className='action_button_cell'>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={careerOpening._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={`View Career Opening`}
        >
          <ViewApplicantDetails targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default CarrerApply;
