import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { IoPersonAdd } from "react-icons/io5";
import { FaRegLifeRing } from "react-icons/fa";

function CareerScreen() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"why Join Medipac"} navCardLink={"/why-join-medipac"}>
        <IoPersonAdd />
      </NavCard>
      <NavCard cardName={"Career-Openings"} navCardLink={"/career-openings"}>
        <FaRegLifeRing />
      </NavCard>
    </NavCardList>
  );
}

export default CareerScreen;
