import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiImageLine } from "react-icons/ri";
import { AiOutlineSolution } from "react-icons/ai";
import { MdOutlineMessage } from "react-icons/md";
function Dashboard() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"Home-Banner"} navCardLink={"/banner"}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={"OurSolution"} navCardLink={"/ourSolution"}>
        <AiOutlineSolution />
      </NavCard>
      <NavCard cardName={"Newsletter"} navCardLink={"/newsletter"}>
        <MdOutlineMessage />
      </NavCard>
    </NavCardList>
  );
}

export default Dashboard;
