import React, { useEffect, useState } from "react";
import { OUR_SOLUTION_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewOurSolution({ targetID }) {
  const [testimonialInfo, setTestimonialTeamInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(OUR_SOLUTION_API + targetID);
      setTestimonialTeamInfo(data);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {testimonialInfo && (
        <>
          <h1>Name</h1>
          <p>{testimonialInfo.name}</p>

          <h1>Designation</h1>
          <p>{testimonialInfo.description}</p>
          <h1>BackgroundColor</h1>
          <p>{testimonialInfo.backgroundColor}</p>
          <h1> Image</h1>
          <Image imgLink={testimonialInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewOurSolution;
