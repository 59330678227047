import InputBoilerplate from "../InputBoilerplate";

function ShortTextInput({
  label,
  value,
  placeholder,
  setState,
  groupStyle,
  inputStyle,
}) {
  return (
    <InputBoilerplate
      label={label}
      type={"text"}
      value={value}
      placeholder={placeholder}
      setState={setState}
      groupStyle={groupStyle}
      inputStyle={inputStyle}
    />
  );
}

export default ShortTextInput;
