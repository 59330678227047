function AddButton({addListItem}) {
    return (
        <button
        style={{
          padding: "18px",
          borderRadius: "10px",
          fontWeight: "700",
          fontSize: "1.5rem",
          width: "20%",
          background: "#fff",
          height: "100%",
          marginBottom: "24px",
        }}
        type="button"
        onClick={addListItem}
      >
        Add
      </button>
    )
  }
  
  export default AddButton