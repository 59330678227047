import { useContext, useMemo } from "react";
import AppContext from "../../AppContext/AppContext";
import axios from "axios";
function useAxiosInstance() {
  const { employee } = useContext(AppContext);

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL + "api/",
        headers: {
          Authorization: "Bearer " + employee?.token,
        },
      }),
    [employee]
  );

  return axiosInstance;
}

export default useAxiosInstance;
