import { useEffect, useState } from "react";
// import { RiDeleteBin2Line } from "react-icons/ri"

import { EMPLOYEES_API } from "../../../../Utilities/APIs";
// import Modal from "../../../Partials/Elements/Modal/Modal"
// import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
// import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"

function Employees() {
  const [employees, setEmployees] = useState(null);
  // const [showModal, setShowModal] = useState(false)
  // const [targetID, setTargetID] = useState(null)
  // const [toggleFetch, setToggleFetch] = useState(false)
  // const [showDeleteSection, setShowDeleteSection] = useState(false)

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmployees() {
      const { data } = await axiosInstance.get(
        EMPLOYEES_API + "getAllEmployees"
      );
      setEmployees(data);
    }

    fetchAndSetEmployees();
  }, []);

  // function triggerFetch() {
  //     setToggleFetch(prevState => !prevState)
  // }

  // function handleClose() {
  //     setShowModal(false)
  // }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Employees (${employees?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Image" />
            <CRUDth th="Name" />
            <CRUDth th="Email Address" />
            <CRUDth th="Level" />
            {/* <CRUDth th= 'Actions' /> */}
          </CRUDTableHeader>
          <tbody>
            {employees?.map((e) => (
              <CRUDTableRow key={e._id}>
                <ImageCell imgSrc={e.dp} imgAlt={e.name} />
                <ShortTextCell text={e.name} />
                <ShortTextCell text={e.email} />
                <ShortTextCell text={e.level} />
                {/* <td className="action_button_cell">
                                    <CRUDButton handleClick={() => {
                                        setTargetID(e._id)
                                        setShowModal(true)
                                        setShowDeleteSection(true)
                                    }}>
                                        <RiDeleteBin2Line />
                                    </CRUDButton>
                                </td> */}
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {/* {showModal && 
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showDeleteSection && `Delete Invite`)
                    }
                >
                    {
                        
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            // common props
                            api={EMPLOYEES_API}
                            triggerFetch={triggerFetch}
                            targetID={targetID}
                            
                            setShowModal={setShowModal}
                            // delete props
                            setShowDeleteSection={setShowDeleteSection}
                            // field props
                            hasName
                        />
                    }
                    
                </Modal>
            } */}
    </>
  );
}

export default Employees;
