import { useState, useEffect } from "react";

import { CONTACTS_API } from "../../../Utilities/APIs";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewContact({
  //common props
  targetID,
  employee,
}) {
  const [contact, setContact] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetInfo() {
      const { data } = await axiosInstance.get(CONTACTS_API + targetID);
      setContact(data);
      console.log("data12345", data);
    }
    fetchAndSetInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {contact && (
        <>
          <h1>Name</h1>
          <p>{contact.name}</p>

          <h1>Contact Type</h1>
          <p>{contact.contactType}</p>

          <h1>Email</h1>
          <p>{contact.email}</p>

          <h1>Phone</h1>
          <p>{contact.phone}</p>

          <h1>Company</h1>
          <p>{contact.company}</p>

          <h1>Message</h1>
          <p>{contact.message}</p>
        </>
      )}
    </div>
  );
}

export default ViewContact;
