import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { GalleryItem_API, Gallery_API, Gallery_Single_Item_API } from "../../../../Utilities/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";
import CreateGalleryItem from "./CreateGalleryItem";
import UpdateGalleryItem from "./UpdateGalleryItem";

function GalleryItem() {
  const { galleryId } = useParams();

  const [showViewSection, setShowViewSection] = useState("");
  const [images, setImages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetGalleryItems() {
      const { data } = await axiosInstance.get(GalleryItem_API + galleryId);
      console.log(data);
      setImages(data);
    }
    fetchAndSetGalleryItems();
  }, [axiosInstance, toggleFetch, galleryId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  console.log("")
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">
            Gallery Images ({images?.length} in total)
          </h1>

          <CreateButton
            screenTopicSingular={"Gallery Image"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Images" />
            <CRUDth th="Precedence" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {images &&
              images?.map((image) => (
                <CRUDTableRow key={image?._id}>
                  <ImageCell
                    imgSrc={process.env.REACT_APP_SPACES_URL + image?.image}
                    alt={"team member"}
                  />
                  <ShortTextCell text={image?.precedence} />

                  <td className="action_button_cell">
                    {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={image._id}
                    setTargetID={setTargetID}
                  /> */}
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Gallery`) ||
            (showUpdateForm && `Update Street `) ||
            (showDeleteSection && `Delete Street`)
          }
        >
          {showCreateForm && (
            <CreateGalleryItem
              employee={employee}
              galleryId={galleryId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateGalleryItem
              employee={employee}
              galleryId={galleryId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={Gallery_Single_Item_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default GalleryItem;
