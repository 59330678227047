import React, { useEffect, useState } from "react";
import { NEWS_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewNews({ targetID }) {
  const [newsInfo, setNewsInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetNewsInfo() {
      const { data } = await axiosInstance.get(NEWS_API + targetID);
      setNewsInfo(data);
    }
    fetchAndSetNewsInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {newsInfo && (
        <>
          <h1>Title</h1>
          <p>{newsInfo.title}</p>

          <h1>Card Description</h1>
          <p>{newsInfo?.cardDescription}</p>

          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: newsInfo?.description }} />
          <h1>Time</h1>
          <p>{newsInfo.time}</p>
          <h1> Image</h1>
          <Image imgLink={newsInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewNews;
