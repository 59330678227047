import React, { useEffect, useState } from "react";
import { CaseStudies_API, Gallery_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDViewBoilerPlate/CRUDViewBoilerPlate.css";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewGallery({ targetID }) {
  const [GalleryInfo, setGalleryInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetGalleryInfo() {
      const { data } = await axiosInstance.get(Gallery_API + targetID);
      setGalleryInfo(data);
    }
    fetchAndSetGalleryInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {GalleryInfo && (
        <>
          <h1>Title</h1>
          <p>{GalleryInfo.title}</p>

          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: GalleryInfo?.description }} />
          <h1>Time</h1>
          <p>{GalleryInfo.time}</p>
          <h1> Image</h1>
          <Image imgLink={GalleryInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewGallery;
