import { useState } from 'react';
import { CAREER_APPLY_API } from '../../../../../Utilities/APIs';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { useEffect } from 'react';

function ViewApplicantDetails({ targetID }) {
  console.log(targetID);
  const [careerOpeningsInfo, setCareerOpeningsInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetCareerOpeningsInfo() {
      const { data } = await axiosInstance.get(
        CAREER_APPLY_API + 'getSingleCarrerApply/' + targetID,
      );
      setCareerOpeningsInfo(data);
      console.log(data);
    }
    fetchAndSetCareerOpeningsInfo();
  }, [targetID]);

  console.log('careerOpeningsInfo:', careerOpeningsInfo);

  return (
    <div className='crud_view_content'>
      {careerOpeningsInfo && (
        <>
          <h1>Name</h1>
          <p>
            {careerOpeningsInfo.firstName} {careerOpeningsInfo.lastName}
          </p>

          <h1>Email</h1>
          <p>{careerOpeningsInfo.email}</p>
          <h1> Phone</h1>
          <p>{careerOpeningsInfo.phone}</p>
          <h1> Address</h1>
          <p>{careerOpeningsInfo.address}</p>
          <h1>Resume</h1>
          <a
            href={process.env.REACT_APP_SPACES_URL+careerOpeningsInfo.resume}
            target='_blank'
          >
            View Resume
          </a>
        </>
      )}
    </div>
  );
}

export default ViewApplicantDetails;
