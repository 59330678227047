import React, { useEffect, useState } from "react";
import Image from "../../Partials/Elements/Image/Image";
import { SocialLinks_API } from "../../../Utilities/APIs";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewViewSocialLinks({ targetID }) {
  const [ViewSocialLinksInfo, setViewSocialLinksInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetViewSocialLinksInfo() {
      const { data } = await axiosInstance.get(SocialLinks_API + targetID);
      setViewSocialLinksInfo(data);
    }
    fetchAndSetViewSocialLinksInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {ViewSocialLinksInfo && (
        <>
          <h1>Name</h1>
          <p>{ViewSocialLinksInfo.name}</p>
          <h1>Precedence</h1>
          <p>{ViewSocialLinksInfo.precedence}</p>
          <h1>Link</h1>
          <p>{ViewSocialLinksInfo.link}</p>
        </>
      )}
    </div>
  );
}

export default ViewViewSocialLinks;
