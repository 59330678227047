import React, { useEffect, useState } from "react";
import { IMAGE_URL, Supplier_API } from "../../../Utilities/APIs";
import Image from "../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewSupplier({ targetID }) {
  const [SupplierInfo, setSupplierInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSupplierInfo() {
      const { data } = await axiosInstance.get(Supplier_API + targetID);
      setSupplierInfo(data);
    }
    fetchAndSetSupplierInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {SupplierInfo && (
        <>
          <h1>Title</h1>
          <p>{SupplierInfo.title}</p>

          <h1>Subtitle</h1>
          <p>{SupplierInfo.subTitle}</p>

          <h1>Description</h1>
          <p>{SupplierInfo?.description}</p>

          {/* <h1>List</h1>
          <ul style={{ listStyle: "auto" }}>
            {SupplierInfo.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul> */}
        </>
      )}
    </div>
  );
}

export default ViewSupplier;
