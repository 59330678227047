import Switch from 'react-switch';

function SwitchInput({ label, toggleSwitch, checked }) {
  return (
    <div
      style={{
        marginBottom: '20px',
      }}
    >
      <label className='input_field_label'>{label}</label>
      <Switch
        className='ecommerce-switch'
        onChange={toggleSwitch}
        checked={checked}
      />
    </div>
  );
}

export default SwitchInput;
