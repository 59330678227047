import React, { useEffect, useState } from "react";
import { PRODUCTS_ASSET_API } from "../../../Utilities/APIs";
import Image from "../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewProductAsset({ targetID }) {
  const [productAssetInfo, setProductAssetInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_ASSET_API + targetID);
      setProductAssetInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {productAssetInfo && (
        <>
          <h1>Precedence</h1>
          <p>{productAssetInfo.precedence}</p>
          <h1>Image</h1>
          <Image imgLink={productAssetInfo.image} imgAlt={"Card Front Image"} />
        </>
      )}
    </div>
  );
}

export default ViewProductAsset;
