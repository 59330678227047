import React, { useEffect, useState } from "react";
import { BLOGS_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewBlogs({ targetID }) {
  const [blogsInfo, setBlogsInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBlogsInfo() {
      const { data } = await axiosInstance.get(BLOGS_API + targetID);
      setBlogsInfo(data);
    }
    fetchAndSetBlogsInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {blogsInfo && (
        <>
          <h1>Title</h1>
          <p>{blogsInfo.title}</p>
          <h1>Card Description</h1>
          <p>{blogsInfo.cardDescription}</p>
          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: blogsInfo?.description }} />
          <h1>Time</h1>
          <p>{blogsInfo.time}</p>
          <h1> Image</h1>
          <Image imgLink={blogsInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewBlogs;
