import ReactTooltip from "react-tooltip";
import "./CRUDButton.css";

function CRUDButton({ children, handleClick, deleteButton, dataTip, dataFor }) {
  return (
    <>
      <button
        className={deleteButton ? "delete_btn" : ""}
        onClick={handleClick}
        data-tip={dataTip}
        data-for={dataFor}
      >
        {children}
      </button>

      <ReactTooltip id={dataFor} place="bottom" effect="solid" />
    </>
  );
}

export default CRUDButton;
