import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { FaBook } from "react-icons/fa";
import { RiImageLine } from "react-icons/ri";

function ResourcesScreen() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"Case Studies"} navCardLink={"/caseStudies"}>
        <FaBook />
      </NavCard>
      <NavCard cardName={"Gallery"} navCardLink={"/gallery"}>
        <RiImageLine />
      </NavCard>
    </NavCardList>
  );
}

export default ResourcesScreen;
