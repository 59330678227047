import React, { useEffect, useState } from "react";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { SocialLinks_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateViewSocialLinks({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [link, setLink] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(SocialLinks_API + targetID);
      setName(data.name);
      setPrecedence(data.precedence);
      setLink(data.link);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("precedence", precedence);
    itemData.append("link", link);

    const response = await axiosInstance.patch(
      SocialLinks_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <NumberInput
        label={`precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ShortTextInput
        label={`Link`}
        value={link}
        placeholder={`Enter Link`}
        setState={setLink}
      />
      <FormSubmitButton text="Update ViewSocialLinks" />
    </Form>
  );
}

export default UpdateViewSocialLinks;
