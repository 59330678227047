import React, { useEffect, useState } from "react";
import { OUr_TEAM_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewOurTeam({ targetID }) {
  const [ourTeamInfo, setOurTeamInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetOurTeamInfo() {
      const { data } = await axiosInstance.get(OUr_TEAM_API + targetID);
      setOurTeamInfo(data);
    }
    fetchAndSetOurTeamInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {ourTeamInfo && (
        <>
          <h1>Name</h1>
          <p>{ourTeamInfo.name}</p>

          <h1>Designation</h1>
          <p>{ourTeamInfo.designation}</p>
          <h1>Description</h1>
          <p>{ourTeamInfo?.description}</p>
          <h1> Image</h1>
          <Image imgLink={ourTeamInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewOurTeam;
