import React, { useEffect, useState } from 'react';

import { CAREER_OPENING_API } from '../../../../Utilities/APIs';
import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import ImageCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell';
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton';
import Modal from '../../../Partials/Elements/Modal/Modal';
import CreateCareerOpenings from './CreateCareerOpenings';
import UpdateCareerOpenings from './UpdateCareerOpenings';
import CRUDDeleteBoilerPlate from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate';
import ViewCareerOpenings from './ViewCareerOpenings';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';
import { RiImage2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

function CareerOpenings() {
  const [careerOpenings, setCareerOpenings] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(CAREER_OPENING_API);
      setCareerOpenings(data);
    }
    fetchAndSetProducts();
  }, [toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <h1 className='heading'>{`Career penings (${careerOpenings?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular='Career Openings'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Name' />
            <CRUDth th='Image' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {careerOpenings?.map((careerOpening) => (
              <CRUDTableRow key={careerOpening._id}>
                <ShortTextCell text={careerOpening.name} />
                <ImageCell imgSrc={careerOpening.image} />

                <td className='action_button_cell'>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={careerOpening._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={careerOpening._id}
                    setTargetID={setTargetID}
                  />
                  <CRUDButton
                    handleClick={() =>
                      navigate('/carrerApply/' + careerOpening._id)
                    }
                    dataTip='See Applicant'
                    dataFor='Applicant'
                  >
                    <RiImage2Line />
                  </CRUDButton>
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={careerOpening._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Testimonial`) ||
            (showUpdateForm && `Update Testimonial `) ||
            (showDeleteSection && `Delete Testimonial`)
          }
        >
          {showCreateForm && (
            <CreateCareerOpenings
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateCareerOpenings
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={CAREER_OPENING_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Hero Slider`}
        >
          <ViewCareerOpenings targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default CareerOpenings;
