import React, { useEffect, useState } from "react";
import { IMAGE_URL, PRODUCTS_API } from "../../../Utilities/APIs";
import Image from "../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewProduct({ targetID }) {
  const [productInfo, setProductInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_API + targetID);
      setProductInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {productInfo && (
        <>
          <h1>Category</h1>
          <p>{productInfo.category?.name}</p>

          <h1>Name</h1>
          <p>{productInfo.name}</p>

          <h1>Model</h1>
          <p>{productInfo?.productModel}</p>

          <h1>Slug</h1>
          <p>{productInfo.slug}</p>

          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: productInfo?.description }} />

          <h1>Card Front Image</h1>
          <Image imgLink={productInfo.image} imgAlt={"Card Front Image"} />
          <h1>pdf</h1>

          <a href={IMAGE_URL + productInfo.pdf}> pdf</a>
        </>
      )}
    </div>
  );
}

export default ViewProduct;
