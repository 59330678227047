import "./InputBoilerplate.css";

function InputBoilerplate({
  label,
  type,
  value,
  placeholder,
  setState,
  inputIsTextArea,
  inputStyle,
  groupStyle,
}) {
  return (
    <div className="input_group" style={{ ...groupStyle }}>
      <label className="input_field_label caption bold">{label}</label>
      {!inputIsTextArea && (
        <input
          className="input_field"
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => setState(e.target.value)}
          style={{ ...inputStyle }}
        />
      )}
      {inputIsTextArea && (
        <textarea
          className="input_field"
          value={value}
          placeholder={placeholder}
          onChange={(e) => setState(e.target.value)}
          style={{ ...inputStyle }}
        />
      )}
    </div>
  );
}

export default InputBoilerplate;
