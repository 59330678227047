import React, { useEffect, useState } from "react";
import { CATEGORYS_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateCategory({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCatagories() {
      const { data } = await axiosInstance.get(CATEGORYS_API + targetID);
      setName(data?.name);
      setPrecedence(data?.precedence);
      setDescription(data.description);
      setImage(data.image);
      setBackgroundColor(data.backgroundColor);
    }
    fetchAndSetCatagories();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("precedence", precedence);
    itemData.append("image", image);
    itemData.append("description", description);
    itemData.append("backgroundColor", backgroundColor);

    const response = await axiosInstance.patch(
      CATEGORYS_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Category Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />
      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`BackgroundColor`}
        value={backgroundColor}
        placeholder={`Enter BackgroundColor`}
        setState={setBackgroundColor}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Category Image
      </ImageInput>
      <FormSubmitButton text="Update Category" />
    </Form>
  );
}

export default UpdateCategory;
