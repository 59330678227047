import React, { useEffect, useState } from "react";
import { IMAGE_URL, SolutionPage_API } from "../../../Utilities/APIs";
import Image from "../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewSolution({ targetID }) {
  const [SolutionInfo, setSolutionInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSolutionInfo() {
      const { data } = await axiosInstance.get(SolutionPage_API + targetID);
      setSolutionInfo(data);
    }
    fetchAndSetSolutionInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {SolutionInfo && (
        <>
          <h1>Title</h1>
          <p>{SolutionInfo.title}</p>

          <h1>Description</h1>
          <div
            dangerouslySetInnerHTML={{ __html: SolutionInfo?.description }}
          />

          <h1>List</h1>
          <ul style={{ listStyle: "auto", marginLeft: "2rem" }}>
            {SolutionInfo.list.map((item, index) => (
              <li key={index} style={{ marginBottom: "5px" }}>
                {item}
              </li>
            ))}
          </ul>

          <h1>Image</h1>
          <Image imgLink={SolutionInfo.image} imgAlt={"Card Front Image"} />
        </>
      )}
    </div>
  );
}

export default ViewSolution;
