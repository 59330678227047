import React, { useEffect, useState } from "react";

import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import CreateViewSocialLinks from "./CreateSocialLinks";
import UpdateViewSocialLinks from "./UpdateSocialLinks";
import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import ViewViewSocialLinks from "./ViewSocialLinks";
import { SocialLinks_API } from "../../../Utilities/APIs";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function SocialLinks() {
  const [ViewSocialLinks, setViewSocialLinks] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(SocialLinks_API);

      console.log("SocialLinks321", data);
      setViewSocialLinks(data);
    }
    fetchAndSetProducts();
  }, [toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`ViewSocialLinks (${ViewSocialLinks?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Testimonial"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Precedence" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {ViewSocialLinks?.map((product) => (
              <CRUDTableRow key={product._id}>
                <ShortTextCell text={product.name} />
                <ShortTextCell text={product.precedence} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create ViewSocialLinks`) ||
            (showUpdateForm && `Update ViewSocialLinks `) ||
            (showDeleteSection && `Delete ViewSocialLinks`)
          }
        >
          {showCreateForm && (
            <CreateViewSocialLinks
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateViewSocialLinks
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={SocialLinks_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Hero Slider`}
        >
          <ViewViewSocialLinks targetID={targetID} />
        </Modal>
      )}
    </>
  );
}
export default SocialLinks;
