import React, { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { Gallery_API, NEWS_API } from "../../../../Utilities/APIs";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateGallery({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("title", title);
    itemData.append("description", description);

    const response = await axiosInstance.post(Gallery_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />

      {/* <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            /> */}
      <CustomEditor setState={setDescription} />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Image
      </ImageInput>
      <FormSubmitButton text="Create Our Team" />
    </Form>
  );
}
export default CreateGallery;
