import React, { useEffect, useState } from "react";
import { MANAGER_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewManager({ targetID }) {
  const [managerInfo, setManagerInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(MANAGER_API + targetID);
      setManagerInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {managerInfo && (
        <>
          <h1>Name</h1>
          <p>{managerInfo.name}</p>

          <h1>Designation</h1>
          <p>{managerInfo.designation}</p>

          <h1>Description</h1>
          <p>{managerInfo.description}</p>

          <h1> Image</h1>
          <Image imgLink={managerInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewManager;
