import React, { useEffect, useState } from "react";
import { NewsImage_API } from "../../../../../../Utilities/APIs";
import Image from "../../../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Video from "../../../../../Partials/Elements/Video/Video";

function ViewNewsImage({ targetID }) {
  const [newsInfo, setNewsInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetNewsInfo() {
      const { data } = await axiosInstance.get(NewsImage_API + targetID);
      setNewsInfo(data);
    }
    fetchAndSetNewsInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {newsInfo && (
        <>
          <h1>Card Image</h1>
          <Image imgLink={newsInfo.image} imgAlt={"Image"} />
          <h1> Video</h1>
          {newsInfo.video ? <Video src={newsInfo.video} /> : "No Video"}
        </>
      )}
    </div>
  );
}

export default ViewNewsImage;
