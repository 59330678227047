import React, { useEffect, useState } from "react";
import {
  CATEGORYS_API,
  PRODUCTS_API,
  PRODUCTS_ASSET_API,
} from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function CreateProductAsset({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  productId,
}) {
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("product", productId);
    itemData.append("precedence", precedence);
    itemData.append("image", image);

    const response = await axiosInstance.post(PRODUCTS_ASSET_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload product Image
      </ImageInput>
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Precedence`}
        setState={setPrecedence}
      />
      <FormSubmitButton text="Create Product Asser" />
    </Form>
  );
}

export default CreateProductAsset;
