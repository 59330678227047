import "./VideoInput.css";
import ShortTextInput from "../ShortTextInput/ShortTextInput";
import YouTube from "react-youtube";

function VideoInput({ fieldId, state, setState }) {
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <label className="upload_file_wrapper" htmlFor={`fileInput${fieldId}`}>
        <span className="input_field_label">Youtube Video</span>
        <div className="preview_image">
          <YouTube videoId={state} opts={opts} />

          <ShortTextInput
            label={`Youtube Video ID`}
            value={state}
            placeholder={`Enter Video ID`}
            setState={setState}
          />
        </div>
      </label>
    </>
  );
}

export default VideoInput;
