import React, { useEffect, useState } from "react";
import { BANNER_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewBanner({ targetID }) {
  const [bannerInfo, setBannerInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetBannerInfo() {
      const { data } = await axiosInstance.get(BANNER_API + targetID);
      setBannerInfo(data);
    }
    fetchAndSetBannerInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {bannerInfo && (
        <>
          <h1>Name</h1>
          <p>{bannerInfo.name}</p>
          <h1>Title</h1>
          <p>{bannerInfo.title}</p>

          <h1>Designation</h1>
          <p>{bannerInfo.description}</p>

          <h1>Video</h1>
          <p>{bannerInfo.video}</p>
          <h1> Image</h1>
          <Image imgLink={bannerInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewBanner;
