import React from "react";
import { RiImageLine } from "react-icons/ri";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function Settings() {
  return (
    <NavCardList numOfCards={"four"}>
      <NavCard cardName={"social-links"} navCardLink={"/socialLinks"}>
        <RiImageLine />
      </NavCard>
    </NavCardList>
  );
}

export default Settings;
