import React, { useEffect, useState } from "react";
import { CAREER_OPENING_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewCareerOpenings({ targetID }) {
  const [careerOpeningsInfo, setCareerOpeningsInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetCareerOpeningsInfo() {
      const { data } = await axiosInstance.get(CAREER_OPENING_API + targetID);
      setCareerOpeningsInfo(data);
    }
    fetchAndSetCareerOpeningsInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {careerOpeningsInfo && (
        <>
          <h1>Name</h1>
          <p>{careerOpeningsInfo.name}</p>

          <h1>Designation</h1>
          <p>{careerOpeningsInfo.description}</p>
          <h1> salaryRange</h1>
          <p>{careerOpeningsInfo.salaryRange}</p>
          <h1> time</h1>
          <p>{careerOpeningsInfo.time}</p>
          <h1> Image</h1>
          <Image imgLink={careerOpeningsInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewCareerOpenings;
