import React, { useEffect, useState } from "react";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

import { Supplier_API } from "../../../Utilities/APIs";
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateSupplier({
  setShowModal,
  setShowUpdateForm,
  targetID,
  triggerFetch,
  employee,
}) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const axiosInstance = useAxiosInstance();
  // const [list, setList] = useState([]);
  // const [listValue, setListValue] = useState("");

  // const addListItem = () => {
  //   setList([...list, listValue]);
  //   setListValue("");
  // };

  useEffect(() => {
    async function fetchAndSetSolutionInfo() {
      const { data } = await axiosInstance.get(Supplier_API + targetID);
      setTitle(data.title);
      setDescription(data.description);
    }
    fetchAndSetSolutionInfo();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    // const itemData = new FormData();
    const itemData = { title, description, subTitle };

    const response = await axiosInstance.patch(
      Supplier_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Subtitle`}
        value={subTitle}
        placeholder={`Enter Subtitle`}
        setState={setSubTitle}
      />
      <CustomEditor setState={setDescription} data={description || ""} />

      {/* <ul
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "25px",
          flexWrap: "wrap",
          marginTop: "15px",
          marginBottom: "15px",
          //   border: "1px solid rgba(255, 255, 255)",
        }}
      >
        {list.map((item, index) => (
          <li key={index}>
            {index + 1}.{item}
          </li>
        ))}
      </ul>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "end",
          gap: "12px",
        }}
      >
        <ShortTextInput
          label={`List`}
          value={listValue}
          placeholder={`Enter Add a List`}
          setState={setListValue}
          groupStyle={{ width: "80%" }}
        />

<AddButton addListItem={addListItem} />
      </div> */}

      <FormSubmitButton text="Update Suplier" />
    </Form>
  );
}

export default UpdateSupplier;
