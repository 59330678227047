import React, { useEffect, useState } from "react";
import { ABOUT_US_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewAboutUs({ targetID }) {
  const [aboutInfo, setAboutInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(ABOUT_US_API + targetID);
      setAboutInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {aboutInfo && (
        <>
          <h1>Name</h1>
          <p>{aboutInfo.name}</p>

          <h1>Title</h1>
          <p>{aboutInfo.title}</p>

          <h1>Description</h1>
          <p>{aboutInfo.description}</p>

          <h1>Card Front Image1</h1>
          <Image imgLink={aboutInfo.image1} imgAlt={"Card Front Image1"} />
          <h1>Card Front Image2</h1>
          <Image imgLink={aboutInfo.image2} imgAlt={"Card Front Image2"} />
        </>
      )}
    </div>
  );
}
export default ViewAboutUs;
