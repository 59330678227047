import React, { useEffect, useState } from "react";
import { CATEGORYS_API, OUR_SOLUTION_API } from "../../../../Utilities/APIs";

import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreateOurSolution({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const axiosInstance = useAxiosInstance();

  const [catagories, setCatagories] = useState(null);
  const [category, setCategory] = useState("");

  useEffect(() => {
    async function fetchAndSetCatagories() {
      const { data } = await axiosInstance.get(CATEGORYS_API);
      setCatagories(data);
    }
    fetchAndSetCatagories();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("category", category);

    itemData.append("name", name);
    itemData.append("image", image);
    itemData.append("precedence", precedence);
    itemData.append("description", description);
    itemData.append("backgroundColor", backgroundColor);

    const response = await axiosInstance.post(OUR_SOLUTION_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Category"} value={category} setState={setCategory}>
        <SelectOption optionValue="" optionText="Select Category" />
        {catagories?.map((category) => (
          <SelectOption
            optionValue={category._id}
            key={category._id}
            optionText={category?.name}
          />
        ))}
      </SelectInput>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`BackgroundColor`}
        value={backgroundColor}
        placeholder={`Enter BackgroundColor`}
        setState={setBackgroundColor}
      />
      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Our Solution
      </ImageInput>
      <FormSubmitButton text="Create Our Solution" />
    </Form>
  );
}

export default CreateOurSolution;
