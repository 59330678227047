import React, { useState } from "react";
import { CaseStudy_IMAGES_API } from "../../../../../../Utilities/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import VideoInput from "../../../../../Partials/Layouts/Forms/FormInputs/VideoInput/VideoInput";

function CreateCaseStudyImage({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  CaseStudyId,
}) {
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("video", video);
    itemData.append("CaseStudyId", CaseStudyId);

    const response = await axiosInstance.post(CaseStudy_IMAGES_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage={true}
      >
        Upload Card Image
      </ImageInput>
      <VideoInput
        fieldId="2"
        state={video}
        setState={setVideo}
        allowCreateImage={true}
      >
        Upload Video
      </VideoInput>
      <FormSubmitButton text="Create" />
    </Form>
  );
}
export default CreateCaseStudyImage;
