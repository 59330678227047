import React, { useEffect, useState } from "react";
import { BLOG_IMAGES_API } from "../../../../../../Utilities/APIs";
import Image from "../../../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Video from "../../../../../Partials/Elements/Video/Video";

function ViewBlogImage({ targetID }) {
  const [BlogInfo, setBlogInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBlogInfo() {
      const { data } = await axiosInstance.get(BLOG_IMAGES_API + targetID);
      setBlogInfo(data);
    }
    fetchAndSetBlogInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {BlogInfo && (
        <>
          <h1>Card Image</h1>
          <Image imgLink={BlogInfo.image} imgAlt={"Image"} />
          <h1> Video</h1>
          {BlogInfo.video ? <Video src={BlogInfo.video} /> : "No Video"}
        </>
      )}
    </div>
  );
}

export default ViewBlogImage;
