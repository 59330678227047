import React, { useEffect, useState } from "react";
import { Award_API } from "../../../../Utilities/APIs";
import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewAward({ targetID }) {
  const [AwardInfo, setAwardInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAwardInfo() {
      const { data } = await axiosInstance.get(Award_API + targetID);
      setAwardInfo(data);
    }
    fetchAndSetAwardInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {AwardInfo && (
        <>
          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: AwardInfo?.description }} />
          <h1>Card Description</h1>
          <p>{AwardInfo.cardDescription}</p>
          <h1>Title</h1>
          <p>{AwardInfo.title}</p>
          <h1>Time</h1>
          <p>{AwardInfo.time}</p>
          <h1> Image</h1>
          <Image imgLink={AwardInfo.image} imgAlt={"Image"} />
        </>
      )}
    </div>
  );
}

export default ViewAward;
