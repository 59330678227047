import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { Gallery_API, NEWS_API } from "./../../../../Utilities/APIs";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateGallery({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetNewsInfo() {
      const { data } = await axiosInstance.get(Gallery_API + targetID);
      console.log("data 54321", data);
      setTitle(data.title);
      setDescription(data.description);
      setImage(data.image);
    }
    fetchAndSetNewsInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("image", image);
    itemData.append("title", title);
    itemData.append("description", description);

    const response = await axiosInstance.patch(
      Gallery_API  + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />

      {/* <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            /> */}
      <CustomEditor setState={setDescription} data={description} />

      {/* <ShortTextInput
        label={`Time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      /> */}
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Image
      </ImageInput>
      <FormSubmitButton text="Update News" />
    </Form>
  );
}
export default UpdateGallery;
